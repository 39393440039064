import React from "react";
import Navbar from "./components/navbar/Navbar";
const App = () => {
  return (
    <React.StrictMode>
      <Navbar />
    </React.StrictMode>
  );
};

export default App;
