import React from "react";
import Footer from "../footer/Footer";
import ebu from "../../assets/ebu.png";
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: require("../../assets/image1.webp"),
    thumbnail: require("../../assets/image1.webp"),
  },
  {
    original: require("../../assets/image2.webp"),
    thumbnail: require("../../assets/image3.webp"),
  },
  {
    original: require("../../assets/image3.webp"),
    thumbnail: require("../../assets/image3.webp"),
  },
  {
    original: require("../../assets/image4.webp"),
    thumbnail: require("../../assets/image4.webp"),
  },
  {
    original: require("../../assets/image5.webp"),
    thumbnail: require("../../assets/image5.webp"),
  },
  {
    original: require("../../assets/backgroundNasser.webp"),
    thumbnail: require("../../assets/backgroundNasser.webp"),
  },
];

const Praxis = () => {
  return (
    <div>
      <div className="praxis">
        <div>
          <p style={{ color: "#00387b", fontSize: 30, fontWeight: "bold" }}>
            HERZLICH WILLKOMMEN IN UNSERER PRAXIS
          </p>

          <p style={{ fontSize: 18, color: "#00387b" }}>
            Nasser AlMuliki ist seit 16 Jahren in der Urologie tätig und
            praktiziert seit 10 Jahren als Facharzt für Urologie und
            Kinderurologie.
            <pre /> Er ist ein Fellow of European Board of Urology (FEBU) und
            hat 2021 seine Praxis in Montabaur / Westerwald eröffnet.
            <pre /> Nachfolgend finden Sie einige Impressionen unserer Praxis.
          </p>
          <a href="https://www.ebu.com/" target="_blank" rel="noreferrer">
            <img style={{ width: 150 }} src={ebu} alt="EBU" />
          </a>
        </div>
        <div>
          <ImageGallery layout="columns" items={images} />
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Praxis;
