import { useState } from "react";
import Footer from "../footer/Footer";
import nasserLeistung from "../../assets/nasser-leistung.png";
import nasserSign from "../../assets/nassr-sign.png";
import { Helmet } from "react-helmet";

const Leistungen = () => {
  const [showVorsorge, setVorsorge] = useState(false);
  const [showProstat, setProstat] = useState(false);
  const [showPalliativMedizin, setPalliativMedizin] = useState(false);
  const [showKrebsTherapie, setKrebsTherapie] = useState(false);
  const [showVasektomi, setVasektomi] = useState(false);

  return (
    <div>
      <div className="leistung">
        <Helmet>
          <title>
            VORSORGE | PROSTATA MRT | PALLIATIVMEDIZIN | KREBSTHERAPIE |
            VASEKTOMIE - STERILISATION DES MANNES |
          </title>
        </Helmet>
        <img
          style={{ width: 300, borderRadius: 150, border: 2 }}
          src={nasserLeistung}
          alt="Nasser"
        />
        <p style={{ fontSize: 17, color: "#00387b", textAlign: "left" }}>
          Liebe Patientin,lieber Patient,
          <pre />
          ich freue mich, Sie in meiner Praxis in Montabaur begrüßen zu dürfen.
          Hier berate ich Sie gern bei Fragen zur Fruchtbarkeit oder akuten
          Beschwerden wie Schmerzen beim Wasserlassen. Vereinbaren Sie Ihren
          Termin direkt online!
          <pre />
          Gemeinsam mit meinem Team heiße ich Sie in meiner modern
          ausgestatteten Praxis willkommen. Durch kontinuierliche Weiterbildung
          und menschliches Verständnis möchte ich Ihnen die Behandlung in meinen
          Räumlichkeiten so angenehm wie möglich gestalten und mithilfe
          neuartiger Behandlungsmethoden zum Erhalt Ihrer Gesundheit beitragen.
          <pre />
          Ich decke das gesamte Leistungsspektrum der Urologie ab. So führe ich
          Krebsvorsorgeuntersuchungen durch und behandle klassische urologische
          Krankheiten wie Prostatavergrößerungen, Entzündungen der Harnwege und
          Jucken oder Ausschlag am Genitalbereich. Gerne stehe ich Ihnen auch
          bei allen Fragen zum Thema Erektionsstörungen zur Seite.
          <pre />
          <br /> Langjährige Erfahrung, neuestes Wissen und moderne Technik,
          kombiniert mit Zuwendung und Zuhören ermöglichen mir eine umfassende
          und kompetente Diagnose und Therapie.
        </p>
        <img
          style={{ width: 300, borderRadius: 150, border: 2 }}
          src={nasserSign}
          alt="Nasser"
        />
      </div>
      <div className="leistungen">
        <h1>UNSERE LEISTUNGEN:</h1>
        <div className="leistungenDiv">
          <h3>
            <button
              id="VORSORGE"
              onClick={() => {
                setVorsorge(!showVorsorge);
              }}
            >
              VORSORGE
            </button>
            <i class="arrow down"></i>
          </h3>
          <hr />
          {showVorsorge && (
            <p>
              Früher oder später stellt sich jeder Mann die Frage, ob seine
              Prostata in Ordnung ist. Das ist gut so, denn regelmäßige Checkups
              sind wichtig. Schließlich ist heutzutage das Prostatakarzinom die
              am weitverbreitetste, bösartige Krebserkrankung beim Mann. Je
              früher die Diagnose erfolgt, umso besser stehen die Chancen zur
              Heilung. Neben der regelmäßigen Krebsvorsorge, die Sie bei uns in
              der Praxis durchführen lassen können, ist ein gesunder Lebensstil
              die beste Vorsorge.
              <pre /> <strong>Untersuchungsablauf:</strong>
              Untersuchungsablauf: Bei der Ursuchung wird die Prostata durch den
              Darm abgetastet. Um auch eine Krebserkrankung im sehr frühen
              Stadium zu erkennen, empfehlen wir zusätzlich zu den
              Kassenleistungen eine Untersuchung mit Ultraschall sowie die die
              PSA-Wert. Das Prostataspezifische-Antigen (PSA) ist ein Eiweiß,
              das nur durch Prostatazellen gebildet wird. Vergrößert sich die
              Prostata, steigt der PSA-Wert, was auf eine Erkrankung hindeuten
              kann.
            </p>
          )}

          <h3>
            <button
              id="PROSTATAMRT"
              onClick={() => {
                setProstat(!showProstat);
              }}
            >
              PROSTATA MRT
            </button>
            <i class="arrow down"></i>
          </h3>
          <hr />
          {showProstat && (
            <p>
              Das Prostataspezifische-Antigen (PSA) ist ein Eiweiß, das nur
              durch Prostatazellen gebildet wird. Vergrößert sich die Prostata,
              steigt der PSA-Wert, was auf eine Erkrankung hindeuten kann. Ist
              der PSA-Wert grenzwertig oder erhöht sowie Ultraschall- und
              Tastergebnis unklar oder negativ, überweisen wir Sie zu einer
              Multiparametrischen Prostata Magnetresonanztomographie (MRT).
              <pre /> Dieses liefert ein genaues Bild und Informationen über die
              Beschaffenheit des Gewebes, ohne Sie dabei mit Strahlen zu
              belasten. <pre />
              Wurde eine Gewebeveränderung diagnostiziert, zeigt eine
              MRT-gesteuerte Prostatabiopsie, ob der Tumor gut- oder bösartig
              ist. Dazu werden Gewebeproben zur weiteren Untersuchung entnommen.
              Die MRT-Technik hilft mit Informationen zu Ausdehnung und
              Verbreitung sowie zur Beobachtung der Behandlungsergebnisse. Gerne
              erfahren Sie mehr zur Verfahrenstechnik bei einem persönlichen
              Arztgespräch.
            </p>
          )}

          <h3>
            <button
              id="PALLIATIVMEDIZIN"
              onClick={() => setPalliativMedizin(!showPalliativMedizin)}
            >
              PALLIATIVMEDIZIN
            </button>
            <i class="arrow down"></i>
          </h3>
          <hr />
          {showPalliativMedizin && (
            <p>
              Wenn das Leben eines Patienten in absehbarer Zeit endet, verändert
              sich der Fokus. Für uns ist es zentral, den letzten
              Lebensabschnitt so angenehm und schmerzfrei wie möglich zu machen.
              Sprechen Sie unser Praxisteam an, falls Sie weitere Informationen
              zu dem Thema Palliativmedizin wünschen.{" "}
            </p>
          )}
          <h3>
            <button
              id="KREBSTHERAPIE"
              onClick={() => setKrebsTherapie(!showKrebsTherapie)}
            >
              KREBSTHERAPIE
            </button>
            <i class="arrow down"></i>
          </h3>
          <hr />
          {showKrebsTherapie && (
            <p>
              Krebs ist eine Diagnose, die einen Schock für jeden Menschen
              bedeutet. Unser erfahrenes Praxisteam hilft Ihnen in dieser
              schwierigen Phase und erstellt eine individuelle Therapie, die Ihr
              Gesundwerden in den Mittelpunkt stellt. Auch die Nachsorge und die
              regelmäßige Kontrolle ist in unserer Praxis möglich. Sprechen Sie
              uns an, falls Sie weitere Informationen zu dem Thema Krebstherapie
              wünschen.
            </p>
          )}
          <h3>
            <button id="VASEKTOMI" onClick={() => setVasektomi(!showVasektomi)}>
              VASEKTOMIE - STERILISATION DES MANNES
            </button>
            <i class="arrow down"></i>
          </h3>
          <hr />
          {showVasektomi && (
            <p>
              Falls Sie als Mann den Entschluss gefasst haben, sich durch eine
              Vasektomie sterilisieren zu lassen, stehen wir Ihnen mit
              jahrelanger Erfahrung kompetent zur Seite. Bei dem chirurgischen
              Eingriff werden die Samenleiter (Ductus deferens) im Hodensack
              durchtrennt. Der Eingriff ist ungefährlich und findet in
              ambulanter Lokalanästhesie statt.
            </p>
          )}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Leistungen;
