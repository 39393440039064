import React from "react";
import "./Pages.css";

const TerminSticky = () => {
  return (
    <div className="sticky">
      <img
        src="https://pro.doctolib.de/external_button/doctolib-white-transparent.png"
        alt="Doctolib"
      />
      <br />
      <span>Termin online vereinbaren</span>
      <br /> <br />
      <a
        style={{
          background: "white",
          color: "#0596de",
          borderRadius: 5,
          padding: 5,
        }}
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.doctolib.de/urologie/montabaur/nasser-almuliki?pid=practice-213449&practitioner_id=43115587&speciality_id=1336&utm_campaign=website-button&utm_source=nasser-almuliki-website-button&utm_medium=referral&utm_content=option-7&utm_term=nasser-almuliki"
      >
        Hier klicken
      </a>
    </div>
  );
};

export default TerminSticky;
