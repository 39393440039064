import React from "react";
import "./Pages.css";

const Herzlich = () => {
  return (
    <div className="backgroundImage">
      <div>
        <h4 className="backgroundImageText">Herzlich Willkommen</h4>
        <h1 className="backgroundImageText2">Praxis für Urologie und </h1>
        <h1 className="backgroundImageText2">Kinderurologie in Montabaur</h1>
      </div>
    </div>
  );
};

export default Herzlich;
