import React from "react";
import "./Pages.css";
import qrCode from "../../assets/AlMuliki_QR_Code-241w.webp";

const TerminHome = () => {
  return (
    <div className="termin">
      <p style={{ color: "#00387b", fontSize: 30, fontWeight: "bold" }}>
        Nehmen Sie Kontakt mit unserem Praxisteam auf
      </p>
      <p style={{ color: "#00387b", fontSize: 20 }}>
        <pre /> oder buchen Sie Ihren <strong>Termin online</strong>
        <pre />
        <br />
        Telefon: <strong>02602 94 73 756</strong>
        <pre />
        Telefax: <strong>02602 94 73 757</strong>
        <br />
        <br />
      </p>
      <img width={200} src={qrCode} alt="Anzeige" />
      <br />
      <br />

      <p style={{ color: "#00387b", fontSize: 30, fontWeight: "bold" }}>
        Sprechstundenzeiten:{" "}
      </p>
      <pre />
      <p style={{ fontSize: 20 }}>
        Montag,Dienstag und Donnerstag: <pre />
        <font color="#00387b">Von 8-12 Uhr und 14-17 Uhr</font> <pre />
        Mittwoch (Op-Tag): <font color="#00387b">geschlossen</font> <pre />
        Freitag: <font color="#00387b">Von 8-12 Uhr</font> <pre />
        Notfall-Sprechstunde: <font color="red">(Nach Vereinbarung)</font>
      </p>
    </div>
  );
};

export default TerminHome;
