import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import React, { useState } from "react";
import logo from "../../assets/Logo.webp";

import "./navbar.css";
import { Sling as Hamburger } from "hamburger-react";
import Home from "../pages/Home";
import Praxis from "../pages/Praxis";
import Leistungen from "../pages/Leistungen";
import Kontakt from "../pages/Kontakt";
import Anfahrt from "../pages/Anfahrt";
import Impressum from "../pages/Impressum";
import Datenschutz from "../pages/Datenschutz";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const handleNavBar = () => {
    setOpen(!isOpen);
  };
  return (
    <BrowserRouter>
      <nav className="navbar">
        <div className="container">
          <div>
            <NavLink to="/">
              <img width={300} src={logo} alt="Urologie" />
            </NavLink>
          </div>
          <div className="menu-icon" onClick={handleNavBar}>
            <Hamburger color="#ffffff" toggled={isOpen} toggle={setOpen} />
          </div>
          <div className={`nav-elements  ${isOpen && "active"}`}>
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/praxis">Praxis</NavLink>
              </li>
              <li>
                <NavLink to="/leistungen">Leistungen</NavLink>
              </li>
              <li>
                <a
                  href="https://www.doctolib.de/urologie/montabaur/nasser-almuliki/booking/insurance-sector?profile_skipped=true&telehealth=false&placeId=practice-213449&specialityId=1336"
                  target="_blank"
                  rel="noreferrer"
                >
                  Termine
                </a>
              </li>
              <li>
                <NavLink to="/anfahrt">Anfahrt</NavLink>
              </li>
              <li>
                <NavLink to="/kontakt">Kontakt</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route index element={<Home />} />
        <Route exact path="/praxis" element={<Praxis />} />
        <Route exact path="/leistungen" element={<Leistungen />} />
        <Route exact path="/anfahrt" element={<Anfahrt />} />
        <Route exact path="/kontakt" element={<Kontakt />} />
        <Route exact path="/impressum" element={<Impressum />} />
        <Route exact path="/datenschutz" element={<Datenschutz />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navbar;
