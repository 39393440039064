import React from "react";
import ebu from "../../assets/ebu.png";
import nasser from "../../assets/IMG-3574-342w.webp";
import "./Pages.css";

const Nasser = () => {
  return (
    <div className="nasser">
      <img
        style={{
          height: 300,
          borderRadius: 40,
          border: 2,
        }}
        src={nasser}
        alt="Nasser"
      />
      <span
        style={{
          backgroundColor: "#ebeff5",
          float: "left",
          textAlign: "left",
          paddingLeft: 100,
        }}
      >
        <font
          style={{
            color: "#00387b",
            fontSize: 30,
            fontWeight: "bold",
          }}
        >
          Nasser AlMuliki
        </font>
        <pre />
        Nasser AlMuliki ist seit 16 Jahren in der Urologie tätig und praktiziert{" "}
        <pre />
        seit 10 Jahren als Facharzt für Urologie und Kinderurologie.
        <pre /> Er ist ein Fellow of European Board of Urology (FEBU)
        <pre /> und hat 2021 seine Praxis in Montabaur / Westerwald eröffnet.
        <pre />
        <a href="https://www.ebu.com/" target="_blank" rel="noreferrer">
          <img style={{ width: 150 }} src={ebu} alt="EBU" />
        </a>
      </span>
    </div>
  );
};

export default Nasser;
