import React from "react";
import "./Pages.css";
import chat from "../../assets/chat.png";
import announcement from "../../assets/announcement.png"

const Anzeige = () => {
  return (
    <div className="anzeige">
      <img width={300} src={announcement} alt="Anzeige" /><pre/>
      <p style={{color:"red"}}>
      Sehr geehrte Patienten <pre/>
      Die Praxis ist am 14.03.2025 wegen einer Schulung geschlossen. <pre/>
      <strong>In dringenden Fällen wenden Sie sich bitte an das nächstgelegene Krankenhaus.</strong><pre/>
      Ab Montag den 17.03.2025 sind wir wieder da!<pre/><pre/>
      Wir machen Urlaub vom 24.03.2025-04.04.2025. <pre/>
      Ab dem 07.04.2025 sind wir wie gewohnt ab 08.00 Uhr für Sie da. <pre/>

      Die Praxis ist an folgenden Tagen für Rezepte Überweisungen u.s.w geöffnet.<pre/>

      Montag den 24.03.2025 8:00-14:00<pre/>
      Dienstag den 25.03.2025 8:00-14:00 <pre/>
      Mittwoch den 26.03.2025 geschlossen <pre/>
      Donnerstag den 27.03.2025 8:00-14:00 <pre/>
      Freitag den 28.03.2025 geschlossen <pre/>
      <strong>In dringenden Fällen wenden Sie sich bitte an das nächstgelegene Krankenhaus.</strong>
      </p><pre/><pre/>
      <img width={300} src={chat} alt="Anzeige" />
      <p>
        Sehr geehrte Patienten <pre/>
        <pre/>
        Wir sind eine Facharztpraxis, da kann es schon durchaus zu etwas längeren
        Wartezeiten kommen. Dafür bitte wir um Verständnis.<pre/>
        <pre/>
        Die Untersuchung bei Neupatienten ist immer etwas aufwändiger. Bitte planen
        Sie genügend Zeit ein.<pre/>
        <pre/>
        Wir haben mehrere Untersuchungs-/Behandlungsräume, deswegen kann es
        auch durchaus sein, dass ein Patient, der nach Ihnen kam, vor Ihnen aufgerufen
        wird.<pre/>
        <pre/>
        Viele Patienten kommen z.B. nur zur Blutabnahme, Katheterwechsel usw. und
        müssen an diesem Tag nicht zum Arzt.<pre/>
        Das sind Untersuchungen, die von den Fachangestellten durchgeführt
        werden.<pre/>
        <pre/>
        An manchen Tagen haben wir auch Notfälle. Dies sind Patienten mit z.B.
        Koliken und unter Schmerzen können diese Patienten nicht warten und
        brauchen zeitnah eine Versorgung.<pre/>
        <pre/>
        Wir bitten um Ihr Verständnis<pre/>
        Ihr URO-Praxisteam<pre /><pre />
      </p>
    </div>
  );
};

export default Anzeige;
